
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { BehaviorSubject } from 'rxjs';
import * as firebase from 'firebase';
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import * as  moment from 'moment';
import { map } from "rxjs/operators";
import { info } from 'console';

export class AuthInfo {
  constructor(public $uid: string) { }

  isLoggedIn() {
    return !!this.$uid;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ApisService {

  static UNKNOWN_USER = new AuthInfo(null);
  db = firebase.firestore();
  public authInfo$: BehaviorSubject<AuthInfo> = new BehaviorSubject<AuthInfo>(ApisService.UNKNOWN_USER);
  api = environment.apiUrl;

  constructor(
    private fireAuth: AngularFireAuth,
    private adb: AngularFirestore,
    private http: HttpClient,
    private translateService: TranslateService
  ) { }

  public checkAuth() {
    return new Promise((resolve, reject) => {
      this.fireAuth.auth.onAuthStateChanged(user => {
        console.log(user);
        if (user) {
          localStorage.setItem('uid', user.uid);
          resolve(user);
        } else {
          this.logout();
          localStorage.clear();
          resolve(false);
        }
      });
    });
  }

  public updateFood(uid, id, param): Promise<any> {
    console.log("Updating food ID:", id);

    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("foods")
        .doc(uid)
        .collection("all")
        .doc(id)
        .update(param)
        .then(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  public updateAddress(uid, id, param): Promise<any> {
    console.log("Updating address ID:", id);

    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("address")
        .doc(uid)
        .collection("all")
        .doc(id)
        .update(param)
        .then(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  translate(str) {
    const currentLang = this.translateService.currentLang;
    const returnValue = this.translateService.translations[currentLang][str];
    if (returnValue === undefined) {
      return this.translateService.translations.en_merch[str];
    } else {
      return returnValue;
    }
  }

  public checkEmail(email: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.fireAuth.auth.fetchSignInMethodsForEmail(email).then((info: any) => {
        resolve(info);
      }).catch(error => {
        reject(error);
      });
    });
  }
  public async estimatePeya({ body }) {
    console.log("llego hasta la fn de estimate delivery");

    return this.http
      .post(`${this.api}/peya/estimateDelivery`, body)
      .pipe(
        map((data: any) => {
          console.log("respuesta de la fn api estimate delivery", data);
          return data;
        })
      );
  }

  public register(emails: string, pwd: string, fnames: string, lnames): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.fireAuth.auth.createUserWithEmailAndPassword(emails, pwd)
        .then(res => {
          if (res.user) {
            this.db.collection('users').doc(res.user.uid).set({
              uid: res.user.uid,
              email: emails,
              fname: fnames,
              lname: lnames,
              type: 'venue',
              status: 'active',
              created_at: new Date(),
            });
            this.authInfo$.next(new AuthInfo(res.user.uid));
            resolve(res.user);
          }
        })
        .catch(err => {

          this.authInfo$.next(ApisService.UNKNOWN_USER);
          reject(`login failed ${err}`);
        });
    });
  }



  public createAdminProfile(emails: string, pwd: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.fireAuth.auth.createUserWithEmailAndPassword(emails, pwd)
        .then(res => {
          if (res.user) {
            this.db.collection('users').doc(res.user.uid).set({
              uid: res.user.uid,
              email: emails,
              type: 'admin',
            });
            this.authInfo$.next(new AuthInfo(res.user.uid));
            resolve(res.user);
          }
        })
        .catch(err => {

          this.authInfo$.next(ApisService.UNKNOWN_USER);
          reject(`login failed ${err}`);
        });
    });
  }



  public createVenue(informations: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('venue').doc(informations.uid).set(informations).then((data) => {
        resolve(data);
      }, error => {
        reject(error);
      }).catch(error => {
        reject(error);
      });
    });
  }

  alerts(title, message, type) {
    Swal.fire(
      title,
      message,
      type
    );
  }

  public login(email: string, password: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.fireAuth.auth.signInWithEmailAndPassword(email, password)
        .then(res => {
          if (res.user) {
            this.authInfo$.next(new AuthInfo(res.user.uid));
            resolve(res.user);
          }
        })
        .catch(err => {

          this.authInfo$.next(ApisService.UNKNOWN_USER);
          reject(`login failed ${err}`);
        });
    });
  }

  public createDriver(
    email: string,
    password: string,
    fullname: string,
    coverImage: string,
    descriptions: string,
    phone: string,
    vehicle: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.fireAuth.auth.createUserWithEmailAndPassword(email, password)
        .then(res => {
          if (res.user) {
            this.db.collection('users').doc(res.user.uid).set({
              uid: res.user.uid,
              email: email,
              fullname: fullname,
              coverImage: coverImage,
              descriptions: descriptions,
              fcm_token: '',
              lat: '',
              lng: '',
              phone: phone,
              status: 'inactive',
              type: 'delivery',
              id: res.user.uid,
              current: 'active',
              vehicle: vehicle,
              created_at: new Date(),
            });
            resolve(res.user);
          }
        })
        .catch(err => {

          this.authInfo$.next(ApisService.UNKNOWN_USER);
          reject(`login failed ${err}`);
        });
    });
  }

  public logout(): Promise<void> {
    this.authInfo$.next(ApisService.UNKNOWN_USER);
    // this.db.collection('users').doc(localStorage.getItem('uid')).update({ "fcm_token": firebase.firestore.FieldValue.delete() })
    return this.fireAuth.auth.signOut();
  }

  public getProfile(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('users').doc(id).get().subscribe((profile: any) => {
        resolve(profile.data());
      }, error => {
        reject(error);
      });
    });
  }

  public getRestReview(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('reviews', ref => ref.where('restId', '==', id)).get().subscribe(async (review) => {
        let data = review.docs.map((element) => {
          let item = element.data();
          item.id = element.id;
          if (item && item.uid) {
            item.uid.get().then(function (doc) {
              item.uid = doc.data();
            });
          }
          return item;
        });
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  public getAdmin(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('users', ref => ref.where('type', '==', 'admin')).get().subscribe(async (review) => {
        let data = review.docs.map((element) => {
          let item = element.data();
          item.id = element.id;
          return item;
        });
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  getCurrencyCode() {
    return environment.general.code;
  }

  getCurrecySymbol() {
    return environment.general.symbol;
  }

  public getVenues(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('venue').get().subscribe((venue) => {
        let data = venue.docs.map(element => {
          let item = element.data();
          item.id = element.id;
          return item;
        });
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  public getValidatedVenues(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('venue', ref => ref.where('validation', '==', true)).get().subscribe((venue) => {
        let data = venue.docs.map(element => {
          let item = element.data();
          item.id = element.id;
          return item;
        });
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }
  public getNotValidatedVenues(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('venue', ref => ref.where('validation', '==', false)).get().subscribe((venue) => {
        let data = venue.docs.map(element => {
          let item = element.data();
          item.id = element.id;
          return item;
        });
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }
  public getPendingVenues(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('pendingVenues').get().subscribe((venue) => {
        let data = venue.docs.map(element => {
          let item = element.data();
          item.id = element.id;
          return item;
        });
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  public getConfig(doc): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('config').doc(doc).get().subscribe((config) => {
        let data = config.data();
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }


  public getUsers(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('users').get().subscribe((users) => {
        let data = users.docs.map(element => {
          let item = element.data();
          item.id = element.id;
          return item;
        });
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }
  // public getSomeUsers(): Promise<any> {
  //   return new Promise<any>((resolve, reject) => {
  //     this.adb.collection('users').get().subscribe((users) => {
  //       let data = users.docs.map(element => {
  //         let item = element.data();
  //         item.id = element.id;
  //         return item;
  //       });
  //       resolve(data);
  //     }, error => {
  //       reject(error);
  //     });
  //   });
  // }
  public getSomeUsers(limit: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('users', ref => ref.orderBy('created_at', 'desc').limit(limit)).get().subscribe((orders) => {
        let data = orders.docs.map(element => {
          let item = element.data();
          item.id = element.id;
          return item;
        });

        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }



  public getAllAddressWhere(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      let addressToResolve = []
      this.db.collectionGroup('all').where('lat', '<', 100).get().then((allAddress) => {
        console.log("Collection group all", allAddress);

        allAddress.forEach((doc) => {
          let address = doc.data()

          if (address.address && !address.city) {
            addressToResolve.push(address);
          }
        });
        resolve(addressToResolve);
      }, error => {
        reject(error);
      });
    });
  }

  public getSomeUsers2(startAt: number, limit: number): Promise<any> {
    console.log("llego a getOffers", startAt, limit);

    return new Promise<any>((resolve, reject) => {
      let query = this.adb.collection('users').ref.where('created_at', '<', new Date()).orderBy('created_at')

      // query = query.startAt(startAt).limit(limit);

      query.get().then((venue: any) => {
        console.log("los cupones en api.service", venue.docs);

        let data = venue.docs.map(element => {
          let item = element.data();
          item.id = element.id;
          return item;
        });

        resolve(data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  public getAllAddress(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      let addressToResolve = []
      this.db.collectionGroup('all').get().then((allAddress) => {
        console.log("Collection group all", allAddress);

        allAddress.forEach((doc) => {
          let address = doc.data()

          if (address.address) {
            addressToResolve.push(address);
          }
        });
        resolve(addressToResolve);
      }, error => {
        reject(error);
      });
    });
  }

  public getAllProducts(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      let productsToResolve = []
      this.db.collectionGroup('all').where('price', '>', -1).get().then((allProducts) => {

        allProducts.forEach((doc) => {
          let product = doc.data()

          if (product.price && !product.deleted) {
            productsToResolve.push(product);
          }
        });
        resolve(productsToResolve);
      }, error => {
        reject(error);
      });
    });
  }


  public getDietTypes(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('dietTypes').get().subscribe((opinions) => {
        let data = opinions.docs.map(element => {
          let item = element.data();
          item.id = element.id;
          return item;
        });
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }
  
  public getOpinions(limit: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('opinions', ref => ref.orderBy('submitedAt', 'desc').limit(limit)).get().subscribe((opinions) => {

        let data = opinions.docs.map(element => {

          let item = element.data();
          item.id = element.id;
          return item;
        });
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }


  public getFeedbacks(limit: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('feedback', ref => ref.orderBy('order_id', 'desc').limit(limit)).get().subscribe((opinions) => {
        let data = opinions.docs.map(element => {

          let item = element.data();
          item.id = element.id;
          return item;
        });
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }


  public getAllOrders(limit: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('orders', ref => ref.orderBy('createdAt', 'desc').limit(limit)).get().subscribe((orders) => {
        let data = orders.docs.map(element => {
          let item = element.data();
          item.id = element.id;
          item.vid.get().then(function (doc) {

            item.vid = doc.data();
            item.vid.id = doc.id;
          });
          item.uid.get().then(function (doc) {
            item.uid = doc.data();
            item.uid.id = doc.id;
          });
          if (item.dId && !item.dId.cabify && !item.dId.peya) {
            item.dId.get().then(function (doc) {
              item.dId = doc.data();
              item.dId.id = doc.id;
            });
          }
          return item;
        });

        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  public getVeggieFormOrders(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('orders', ref => ref.orderBy('veggie_form.createdAt', 'desc')).get().subscribe((orders) => {
        let data = orders.docs.map(element => {
          let item = element.data();
          item.id = element.id;
          item.vid.get().then(function (doc) {

            item.vid = doc.data();
            item.vid.id = doc.id;
          });
          item.uid.get().then(function (doc) {
            item.uid = doc.data();
            item.uid.id = doc.id;
          });
          if (item.dId && !item.dId.cabify && !item.dId.peya) {
            item.dId.get().then(function (doc) {
              item.dId = doc.data();
              item.dId.id = doc.id;
            });
          }
          return item;
        });

        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }
  public getChristmasOrders(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('orders', ref => ref.orderBy('createdAt', 'desc').where('christmas', '==', true)).get().subscribe((orders) => {
        let data = orders.docs.map(element => {
          let item = element.data();
          item.id = element.id;
          item.vid.get().then(function (doc) {

            item.vid = doc.data();
            item.vid.id = doc.id;
          });
          item.uid.get().then(function (doc) {
            item.uid = doc.data();
            item.uid.id = doc.id;
          });
          if (item.dId && !item.dId.cabify && !item.dId.peya) {
            item.dId.get().then(function (doc) {
              item.dId = doc.data();
              item.dId.id = doc.id;
            });
          }
          return item;
        });

        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  public getTodayPendingOrders(status): Promise<any> {

    return new Promise<any>((resolve, reject) => {
      console.log("ENTRA A PENDING ORDERS");

      // Obtén la fecha y hora actual y la fecha y hora de hace 24 horas
      const now = new Date();
      const twentyFourHoursAgo = new Date(now);
      twentyFourHoursAgo.setHours(now.getHours() - 24);

      this.adb.collection('pendingOrders', ref => ref.where('status', 'in', status).where("createdAt", ">=", firebase.firestore.Timestamp.fromDate(twentyFourHoursAgo))).get().subscribe((orders) => {
        console.log("Estas son las pendingOrders:", orders);

        let data = orders.docs.map(element => {
          let item = element.data();

          item.id = element.id;

          item.vid.get().then(function (doc) {
            item.vid = doc.data();
            item.vid.id = doc.id;
          });
          item.uid.get().then(function (doc) {
            item.uid = doc.data();
            item.uid.id = doc.id;
          });
          if (item.dId && !item.dId.cabif && !item.dId.peya) {
            item.dId.get().then(function (doc) {
              item.dId = doc.data();
              item.dId.id = doc.id;
            });
          }
          return item;
        });

        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  public getAllErrorOrders(status): Promise<any> {

    return new Promise<any>((resolve, reject) => {
      console.log("ENTRA A PENDING ORDERS");

      this.adb.collection('mpError', ref => ref.where('status', 'in', status)).get().subscribe((orders) => {
        console.log("Estas son las errorOrders:", orders);

        let data = orders.docs.map(element => {
          let item = element.data();

          item.id = element.id;
          console.log(item.id)
          this.getVenueDetails(item.vid).then(function (doc) {
            item.vid = doc;
            item.vid.id = doc.uid;
          });
          this.getMyProfile(item.uid).then(function (doc) {
            item.uid = doc
            item.uid.id = doc.id;
          });
          if (item.dId && !item.dId.cabify && !item.dId.peya) {
            item.dId.get().then(function (doc) {
              item.dId = doc.data();
              item.dId.id = doc.id;
            });
          }
          return item;
        });

        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }
  public getAllPendingOrders(status): Promise<any> {

    return new Promise<any>((resolve, reject) => {
      console.log("ENTRA A PENDING ORDERS");

      this.adb.collection('pendingOrders', ref => ref.where('status', 'in', status)).get().subscribe((orders) => {
        console.log("Estas son las pendingOrders:", orders);

        let data = orders.docs.map(element => {
          let item = element.data();
          console.log(item.vid)
          item.id = element.id;

          item.vid.get().then(function (doc) {
            item.vid = doc.data();
            item.vid.id = doc.id;
          });
          item.uid.get().then(function (doc) {
            item.uid = doc.data();
            item.uid.id = doc.id;
          });
          if (item.dId && !item.dId.cabify && !item.dId.peya) {
            item.dId.get().then(function (doc) {
              item.dId = doc.data();
              item.dId.id = doc.id;
            });
          }
          return item;
        });

        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  public getOrders(status: string[]): Promise<any> {

    // Obtén la fecha y hora actual y la fecha y hora de hace 24 horas
    const now = new Date();
    const twentyFourHoursAgo = new Date(now);
    twentyFourHoursAgo.setHours(now.getHours() - 24);
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('orders', ref => ref.where('createdAt', '>=', firebase.firestore.Timestamp.fromDate(twentyFourHoursAgo)).where('status', 'in', status)).get().subscribe((orders) => {
        let data = orders.docs.map(element => {
          let item = element.data();
          item.id = element.id;
          item.vid.get().then(function (doc) {
            item.vid = doc.data();
            item.vid.id = doc.id;
          });
          item.uid.get().then(function (doc) {
            item.uid = doc.data();
            item.uid.id = doc.id;
          });
          if (item.dId && !item.dId.cabify && !item.dId.peya) {
            item.dId.get().then(function (doc) {
              item.dId = doc.data();
              item.dId.id = doc.id;
            });
          }
          return item;
        });

        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  public getTodayOrders(status: string[]): Promise<any> {
    let today = new Date()
    today.setHours(0, 0, 0, 0)
    console.log("LA FECHA DE HOY:", today.toDateString());
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('orders', ref => ref.where('createdAt', '>=', firebase.firestore.Timestamp.fromDate(today)).where('status', 'in', status)).get().subscribe((orders) => {
        let data = orders.docs.map(element => {
          let item = element.data();
          item.id = element.id;
          item.vid.get().then(function (doc) {
            item.vid = doc.data();
            item.vid.id = doc.id;
          });
          item.uid.get().then(function (doc) {
            item.uid = doc.data();
            item.uid.id = doc.id;
          });
          if (item.dId && !item.dId.cabify && !item.dId.peya) {
            item.dId.get().then(function (doc) {
              item.dId = doc.data();
              item.dId.id = doc.id;
            });
          }
          return item;
        });

        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  sendNoti({ body }) {
    console.log("LLEGA A LA API", body);

    return this.http
      .post<any>(`${this.api}/notifications/sendNoti`, body)
      .pipe(
        map((data: any) => {
          console.log(data);
          return data;
        })
      );
  }

  twilio_whatsApp() {
    console.log("LLEGA A LA FUNCION twilio_whatsApp");

    let body = {
      randomInfo: 'Hola, como estas?'
    }

    return this.http
      .post<any>(`${this.api}/payments/twilio_whatsApp`, body)
      .pipe(
        map((data: any) => {
          console.log(data);
          return data;
        })
      );
  }

  refund({ body }, id) {
    console.log("lo que recibe refund API FRONT", { body });

    return this.http
      .post<any>(`${this.api}/payments/refunds/${id}`, body)
      .pipe(
        map((data: any) => {
          console.log(data);
          if (data.status == "approved") {
            Swal.fire(
              'La devolución se ha realizado con exito',
              'De ' + body.total_amount + " fueron devueltos => " + data.amount_refunded_to_payer,
              'success'
            )
          }
          return data;
        })
      );
  }

  create_short_link({ body }) {

    return this.http
      .post<any>(`${this.api}/urls/shorten`, body)
      .pipe(
        map((data: any) => {
          console.log(data);

          return data;
        })
      );
  }

  create_qr_code({ body }) {

    return this.http
      .post<any>(`${this.api}/urls/generateQRCode`, body)
      .pipe(
        map((data: any) => {
          console.log(data);

          return data;
        })
      );
  }

  refresh_token({ body }) {
    console.log("lo que recibe refund API FRONT", { body });

    return this.http
      .post<any>(`${this.api}/payments/refresh_token`, body)
      .pipe(
        map((data: any) => {
          console.log(data);
          if (data.status == "approved") {
            Swal.fire(
              'La refrescación se ha realizado con exito',
              'success'
            )
          }
          return data;
        })
      );
  }


  public getMpErrors(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('mpError').get().subscribe((errors) => {

        let data = errors.docs.map(element => {

          let item = element.data();
          item.id = element.id;

          return item;
        });

        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  sendNotification(msg, title, id = null, segment, deeplink = null, external_id = null) {
    const body = {
      app_id: environment.onesignal.appId,
      headings: { en: title },
      contents: { en: msg },
      data: { task: msg },
      small_icon: "https://oskatango.com/wp-content/uploads/2022/11/logo-blanco.png",
    };

    if (segment == "single" && id) {
      body['include_player_ids'] = [id]
    }
    if (segment == "all") {
      body["included_segments"] = ['All users']
    }
    if (segment == "team") {
      body['include_player_ids'] = id
    }
    if (deeplink) {
      body['app_url'] = deeplink
      console.log("agregando appurl", body)
    }
    if (segment = 'external_id') {
      body['include_aliases'] = { "external_id": [external_id] }
      body['target_channel'] = "push"
    }

    const header = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', `Basic ${environment.onesignal.restKey}`)
    };
    return this.http.post('https://onesignal.com/api/v1/notifications', body, header);
  }

  public getVenueDetails(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('venue').doc(id).get().subscribe((venue: any) => {
        resolve(venue.data());
      }, error => {
        reject(error);
      });
    });
  }
  public getPendingVenueDetails(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('pendingVenues').doc(id).get().subscribe((venue: any) => {
        resolve(venue.data());
      }, error => {
        reject(error);
      });
    });
  }
  public getCity(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('cities').doc(id).get().subscribe((city: any) => {
        resolve(city.data());
      }, error => {
        reject(error);
      });
    });
  }
  public getVenueTypes(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('venueTypes').get().subscribe((types: any) => {
        var data = types.docs.map(element => {
          var item = element.data();
          item.id = element.id;
          return item;
        })
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }
  public getPromotions(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('promotions').get().subscribe((promotions: any) => {
        var data = promotions.docs.map(element => {
          var item = element.data();
          item.id = element.id;
          return item;
        })
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }
  public getSubCategories(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('subcategories').get().subscribe((subcategories: any) => {
        var data = subcategories.docs.map(element => {
          var item = element.data();
          item.id = element.id;
          return item;
        })
        console.log("getSubCategories", data);

        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  public getMyProfile(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('users').doc(id).get().subscribe((users: any) => {
        resolve(users.data());
      }, error => {
        reject(error);
      });
    });
  }

  public getVenueUser(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('users').doc(id).get().subscribe((venue: any) => {
        resolve(venue.data());
      }, error => {
        reject(error);
      });
    });
  }

  public getVenueCategories(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('categories', ref => ref.where('uid', '==', id)).get().subscribe((venue) => {
        var data = venue.docs.map(element => {
          var item = element.data();
          item.id = element.id;
          return item;
        })
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  public getFoods(uid: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.db.collection('foods').doc(uid).collection('all').get().then((data) => {
        var users = data.docs.map(doc => {
          var item = doc.data();
          item.cid.get().then(function (doc) {
            item.cid = doc.data();
            item.cid.id = doc.id;
          });
          item.id = doc.id;
          return item;
        });
        resolve(users);
      }, error => {
        reject(error);
      });
    });
  }

  public addNewAddress(uid, id, param): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('address').doc(uid).collection('all').doc(id).set(param).then((data) => {
        resolve(data);
      }, error => {
        reject(error);
      }).catch(error => {
        reject(error);
      });
    });
  }

  public addCoupon(id, param): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('offers').doc(id).set(param).then((data) => {
        resolve(data);
      }, error => {
        reject(error);
      }).catch(error => {
        reject(error);
      });
    });
  }


  public addBanner(id, param): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('banners').doc(id).set(param).then((data) => {
        resolve(data);
      }, error => {
        reject(error);
      }).catch(error => {
        reject(error);
      });
    });
  }

  public addCity(id, param): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('cities').doc(id).set(param).then((data) => {
        resolve(data);
      }, error => {
        reject(error);
      }).catch(error => {
        reject(error);
      });
    });
  }

  public getCities(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('cities').get().subscribe((venue: any) => {
        let data = venue.docs.map(element => {
          let item = element.data();
          item.id = element.id;
          return item;
        });
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  public updateOffers(informations: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('offers').doc(informations.id).update(informations).then((data) => {
        console.log("update", informations.code);

        resolve(data);
      }, error => {
        reject(error);
      }).catch(error => {
        reject(error);
      });
    });
  }

  public updateConfig(config, informations: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('config').doc(config).update(informations).then((data) => {
        resolve(data);
      }, error => {
        reject(error);
      }).catch(error => {
        reject(error);
      });
    });
  }

  public getOffers(startAt: number, limit: number): Promise<any> {
    console.log("llego a getOffers", startAt, limit);

    return new Promise<any>((resolve, reject) => {
      let query = this.adb.collection('offers').ref.where('expireDate', '>', new Date()).orderBy('expireDate').orderBy('discout', 'desc');

      query = query.startAt(startAt).limit(limit);

      query.get().then((venue: any) => {
        console.log("los cupones en api.service", venue.docs);

        let data = venue.docs.map(element => {
          let item = element.data();
          item.id = element.id;
          return item;
        });

        resolve(data);
      }).catch(error => {
        reject(error);
      });
    });
  }


  public searchOffers(searchValue): Promise<any> {
    console.log("llego a searchOffers", searchValue);

    return new Promise<any>((resolve, reject) => {
      let query = this.adb.collection('offers').ref.where('code', '==', searchValue);

      query.get().then((venue: any) => {
        console.log("los cupones en api.service", venue.docs);

        let data = venue.docs.map(element => {
          let item = element.data();
          item.id = element.id;
          return item;
        });

        resolve(data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  public getValidOffers(): Promise<any> {
    const today = new Date();
    const formattedToday = today.toISOString().split('T')[0]; // Format as "yyyy-mm-dd"
    console.log('la fecha', formattedToday)
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('offers', ref => ref
        .where('status', '==', 'active')
        .where('expire', '>=', formattedToday)
      ).get().subscribe((venue: any) => {
        console.log("los cupones validos", venue)
        // resolve(venue.data());
        let data = venue.docs.map(element => {
          let item = element.data();
          item.id = element.id;
          return item;
        });
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }
  public getInvalidOffers(): Promise<any> {
    const today = new Date();
    const formattedToday = today.toISOString().split('T')[0]; // Format as "yyyy-mm-dd"
    console.log('la fecha', formattedToday)
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('offers', ref => ref
        .where('expire', '<=', formattedToday)
      ).get().subscribe((venue: any) => {
        // resolve(venue.data());
        console.log("cupones invalidos", venue)
        let data = venue.docs.map(element => {
          let item = element.data();
          item.id = element.id;
          return item;
        });
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  public getOfferByCode(code): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('offers', ref => ref.where('code', '==', code.toUpperCase()).where('status', '==', 'active')).get().subscribe((venue: any) => {
        let data = venue.docs
          .filter(element => {
            let item = element.data();
            item.id = element.id;
            const currentDate = moment().format('YYYY-MM-DD');
            return moment(item.expire).isAfter(currentDate);
          })
          .map(element => {
            let item = element.data();
            item.id = element.id;
            return item;
          });
        resolve(data && data.length ? data[0] : null);
      }, error => {
        reject(error);
      });
    });
  }

  public getUserOffers(uid): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('users').doc(uid).collection('offers').get().subscribe(async (offers: any) => {
        var dataOffers = await offers.docs.map(async offer => {
          var item = await offer.data();
          await item.oid.get().then(async (doc) => {
            item.oid = await doc.data();
            item.oid.id = doc.id;
          });
          item.id = offer.id;
          return item;
        });

        const data = await Promise.all(dataOffers);

        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }


  public updateUserOffer(uid, cuponId, informations): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      console.log('updateUserOffer parameters:', { uid, cuponId, informations });
      if (!cuponId) {
        return reject(new Error('cuponId is undefined'));
      }
      const documentPath = `users/${uid}/offers/${cuponId}`;
      console.log(`Intentando actualizar el documento en la ruta: ${documentPath}`);

      this.adb.collection('users').doc(uid).collection('offers').doc(cuponId).update(informations)
        .then((data) => {
          console.log(`Documento actualizado con éxito en la ruta: ${documentPath}`);
          resolve(data);
        })
        .catch((error) => {
          console.error(`Error al actualizar el documento en la ruta: ${documentPath}`, error);
          reject(error);
        });
    });
  }



  public addUserCoupon(uid, args): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      const offer = await this.getOfferByCode(args.code);
      if (offer) {
        const params = {
          status: 'active',
          oid: this.db.collection('offers').doc(offer.id)
        }

        const offerRef = await this.db.collection('users').doc(uid).collection('offers').doc(offer.id);
        const existOffer = await offerRef.get();
        if (!existOffer.exists) {
          this.adb.collection('users').doc(uid).collection('offers').doc(offer.id).set(params).then((data) => {
            resolve(data);
          }, error => {
            reject(error);
          }).catch(error => {
            reject(error);
          });
        } else {
          reject('Ya tenés cargado ese cupón o ya lo usaste');
        }
      } else {
        reject('El código ingresado no está disponible');
      }
    });
  }

  public getBanners(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('banners').get().subscribe((venue: any) => {
        // resolve(venue.data());
        let data = venue.docs.map(element => {
          let item = element.data();
          item.id = element.id;
          return item;
        });
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  public getMessages(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('messages').doc(id).collection('chats').get().subscribe((messages: any) => {
        console.log(messages);
        let data = messages.docs.map(element => {
          let item = element.data();
          item.id = element.id;
          return item;
        });
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  public getMyAddress(uid: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.db.collection('address').doc(uid).collection('all').get().then((data) => {
        var users = data.docs.map(doc => {
          var item = doc.data();
          item.id = doc.id;
          return item;
        });
        resolve(users);
      }, error => {
        reject(error);
      });
    });
  }

  public createOrder(id, param): Promise<any> {
    param.vid = this.db.collection('venue').doc(param.vid);
    param.uid = this.db.collection('users').doc(param.uid);
    param.dId = this.db.collection('users').doc(param.dId);
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('orders').doc(id).set(param).then((data) => {
        resolve(data);
      }, error => {
        reject(error);
      }).catch(error => {
        reject(error);
      });
    });
  }




  public getMyOrders(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('orders', ref => ref.where('userId', '==', id)).get().subscribe(async (venue) => {
        let data = venue.docs.map(element => {
          let item = element.data();
          item.vid.get().then(function (doc) {
            item.vid = doc.data();
            item.vid.id = doc.id;
          });
          item.id = element.id;
          return item;
        });
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }
  public getMyDeliveredOrders(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('orders', ref => ref.where('userId', '==', id).where('status', '==', 'delivered')).get().subscribe(async (venue) => {
        let data = venue.docs.map(element => {
          let item = element.data();
          item.vid.get().then(function (doc) {
            item.vid = doc.data();
            item.vid.id = doc.id;
          });
          item.id = element.id;
          return item;
        });
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  public getRestOrders(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('orders', ref => ref.where('restId', '==', id)).get().subscribe((venue) => {
        let data = venue.docs.map(element => {
          let item = element.data();
          item.uid.get().then(function (doc) {
            item.uid = doc.data();
            item.uid.id = doc.id;
          });
          item.id = element.id;
          return item;
        });
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  public getOrderById(id): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      this.adb.collection('orders').doc(id).get().subscribe(async (order: any) => {
        let data = await order.data();
        await data.vid.get().then(function (doc) {
          data.vid = doc.data();
          data.vid.id = doc.id;
        });
        await data.uid.get().then(function (doc) {
          data.uid = doc.data();
          data.uid.id = doc.id;
        });
        if (data && data.dId && !data.dId.cabify) {
          await data.dId.get().then(function (doc) {
            data.dId = doc.id;
            data.dId = doc.data();
          });
        }
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  public getPendingOrderById(id): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      this.adb.collection('pendingOrders').doc(id).get().subscribe(async (order: any) => {
        let data = await order.data();
        await data.vid.get().then(function (doc) {
          data.vid = doc.data();
          data.vid.id = doc.id;
        });
        await data.uid.get().then(function (doc) {
          data.uid = doc.data();
          data.uid.id = doc.id;
        });
        if (data && data.dId && !data.dId.cabify) {
          await data.dId.get().then(function (doc) {
            data.dId = doc.id;
            data.dId = doc.data();
          });
        }
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }
  public getErrorOrderById(id): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      this.adb.collection('mpError').doc(id).get().subscribe(async (order: any) => {
        let data = await order.data();
        this.getVenueDetails(data.vid).then(function (doc) {
          data.vid = doc;
          data.vid.id = doc.uid;
        });
        this.getMyProfile(data.uid).then(function (doc) {
          data.uid = doc
          data.uid.id = doc.userId;
        });
        if (data && data.dId && !data.dId.cabify) {
          await data.dId.get().then(function (doc) {
            data.dId = doc.id;
            data.dId = doc.data();
          });
        }
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  public getCoupon(id): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      this.adb.collection('offers').doc(id).get().subscribe(async (coupon: any) => {
        let data = await coupon.data();
        console.log("este es el coupon", data);


        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  getDriverInfo(id): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      this.adb.collection('users').doc(id).snapshotChanges().subscribe(data => {
        console.log(data);
        resolve(data.payload.data());
      }, error => {
        reject(error);
      });
    });
  }


  getDriverLocation(orderId: any) {
    return new Promise<any>(async (resolve, reject) => {
      this.adb.collection('locationUpdates').doc(orderId).snapshotChanges().subscribe(data => {
        console.log(data);
        resolve(data.payload.data());
      }, error => {
        reject(error);
      });
    });
  }


  public getDrivers(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('users', ref => ref.where('type', '==', 'delivery')).get().subscribe(async (venue) => {
        let data = venue.docs.map(element => {
          let item = element.data();
          item.id = element.id;
          return item;
        });
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  public getActiveDrivers(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('users', ref => ref
        .where('type', '==', 'delivery')
        .where('current', '==', 'active')
        .where('status', '==', 'active')
      ).get().subscribe(async (venue) => {
        let data = venue.docs.map(element => {
          let item = element.data();
          item.id = element.id;
          return item;
        });
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }


  public sendOrderToDriver(id, param): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('driverOrders').doc(id).set(param).then((data) => {
        resolve(data);
      }, error => {
        reject(error);
      }).catch(error => {
        reject(error);
      });
    });
  }

  public addReview(param): Promise<any> {
    param.vid = this.db.collection('venue').doc(param.vid);
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('reviews').doc(Math.random().toString()).set(param).then((data) => {
        resolve(data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  public updateVenue(informations: any): Promise<any> {
    console.log("informations: ", informations);

    return new Promise<any>((resolve, reject) => {
      this.adb.collection('venue').doc(informations.uid).update(informations).then((data) => {
        resolve(data);
      }, error => {
        reject(error);
      }).catch(error => {
        reject(error);
      });
    });
  }
  public updatePendingVenue(informations: any): Promise<any> {
    console.log("informations: ", informations);

    return new Promise<any>((resolve, reject) => {
      this.adb.collection('pendingVenues').doc(informations.uid).update(informations).then((data) => {
        resolve(data);
      }, error => {
        reject(error);
      }).catch(error => {
        reject(error);
      });
    });
  }

  public createPending(id, order): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      order.vid = await this.db.collection('venue').doc(order.vid.id)
      order.uid = await this.db.collection('users').doc(order.uid.id)
      this.adb.collection('orders').doc(id).set(order).then((data) => {
        resolve(data);
      }, error => {
        reject(error);
      }).catch(error => {
        reject(error);
      });
    });
  }

  public updateSubcategory(informations: any): Promise<any> {
    console.log("informations: " + informations);

    return new Promise<any>((resolve, reject) => {
      this.adb.collection('subcategories').doc(informations.id).update(informations).then((data) => {
        resolve(data);
      }, error => {
        reject(error);
      }).catch(error => {
        reject(error);
      });
    });
  }

  public updateCoupon(id, informations: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('offers').doc(id).update(informations).then((data) => {
        resolve(data);
      }, error => {
        reject(error);
      }).catch(error => {
        reject(error);
      });
    });
  }


  public updateBanner(informations: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('banners').doc(informations.id).update(informations).then((data) => {
        resolve(data);
      }, error => {
        reject(error);
      }).catch(error => {
        reject(error);
      });
    });
  }

  public updateCity(informations: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('cities').doc(informations.id).update(informations).then((data) => {
        resolve(data);
      }, error => {
        reject(error);
      }).catch(error => {
        reject(error);
      });
    });
  }

  public deleteBanner(informations: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('banners').doc(informations.id).delete().then((data) => {
        resolve(data);
      }, error => {
        reject(error);
      }).catch(error => {
        reject(error);
      });
    });
  }

  public deleteOrder(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('orders').doc(id).delete().then((data) => {
        console.log("Pedido ", id, " eliminado");
        resolve(data);
      }, error => {
        console.log("error", error);
        reject(error);
      }).catch(error => {
        console.log("error", error);
        reject(error);
      });
    });
  }

  public deletePendingOrder(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('pendingOrders').doc(id).delete().then((data) => {
        console.log("Pedido ", id, " eliminado");

        resolve(data);
      }, error => {
        console.log("error", error);
        reject(error);
      }).catch(error => {
        console.log("error", error);
        reject(error);
      });
    });
  }

  public deleteCity(informations: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('cities').doc(informations.id).delete().then((data) => {
        resolve(data);
      }, error => {
        reject(error);
      }).catch(error => {
        reject(error);
      });
    });
  }

  public updateProfile(uid, param): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.db.collection('users').doc(uid).update(param).then((data) => {
        console.log("actualizando el usuario con el id: ", uid, " y el param: ", param);
        resolve(data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  public getMyReviews(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('reviews', ref => ref.where('id', '==', id)).get().subscribe(async (review) => {
        let data = review.docs.map((element) => {
          let item = element.data();
          item.id = element.id;
          if (item && item.vid) {
            item.vid.get().then(function (doc) {
              item.vid = doc.data();
            });
          }

          return item;
        });
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }


  JSON_to_URLEncoded(element, key?, list?) {
    let new_list = list || [];
    if (typeof element == "object") {
      for (let idx in element) {
        this.JSON_to_URLEncoded(
          element[idx],
          key ? key + "[" + idx + "]" : idx,
          new_list
        );
      }
    } else {
      new_list.push(key + "=" + encodeURIComponent(element));
    }
    return new_list.join("&");
  }


  httpPost(url, body) {
    const header = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', `Bearer ${environment.stripe.sk}`)
    };
    const order = this.JSON_to_URLEncoded(body);
    console.log(order);
    return this.http.post(url, order, header);
  }

  public updateOrderStatus(id, value): Promise<any> {
    const param = {
      status: value
    }
    if (value === 'rejected') {
      param['rejectedAt'] = new Date()
    }
    return new Promise<any>(async (resolve, reject) => {
      this.adb.collection('orders').doc(id).update(param).then(async (order: any) => {
        resolve(order);
      }).catch(error => {
        reject(error);
      });
    });
  }
  public updateOrderPayment(id, value): Promise<any> {
    const param = {
      paid: value
    }

    return new Promise<any>(async (resolve, reject) => {
      this.adb.collection('orders').doc(id).update(param).then(async (order: any) => {
        resolve(order);
      }).catch(error => {
        reject(error);
      });
    });
  }

  public updateOrder(id, param): Promise<any> {
    if (param.dId && !param.dId.cabify) {
      param.dId = this.db.collection('users').doc(param.dId);
    }
    return new Promise<any>(async (resolve, reject) => {
      this.adb.collection('orders').doc(id).update(param).then(async (order: any) => {
        resolve(order);
      }).catch(error => {
        reject(error);
      });
    });
  }

  public updateOrders(ids, param): Promise<any> {
    param.dId = this.db.collection('users').doc(param.dId);
    return new Promise<any>(async (resolve, reject) => {
      this.adb.collection('orders', ref => ref.where('orderId', 'in', ids)).get().toPromise().then(response => {
        let batch = firebase.firestore().batch()
        response.docs.forEach((doc) => {
          const docRef: any = this.adb.collection('orders').doc(doc.id)
          batch.update(docRef.ref, param)
        })
        batch.commit().then(() => {
          console.log(`updated all documents inside orders`)
          resolve(true)
        }).catch(err => {
          console.log(err);
          reject(err)
        });
      })
    });
  }

  public newSubcategorie(param): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('subcategories').doc(param.sid).set(param).then((data) => {
        resolve(data);
      }, error => {
        reject(error);
      }).catch(error => {
        reject(error);
      });
    });
  }
  public newPromotion(param): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('promotions').doc(param.pid).set(param).then((data) => {
        resolve(data);
      }, error => {
        reject(error);
      }).catch(error => {
        reject(error);
      });
    });
  }

  public deletePendingVenue(uid): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('pendingVenues').doc(uid).delete().then((data) => {
        resolve(data);
      }, error => {
        reject(error);
      }).catch(error => {
        reject(error);
      });
    });
  }
  public deleteSubcategorie(sid): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('subcategories').doc(sid).delete().then((data) => {
        resolve(data);
      }, error => {
        reject(error);
      }).catch(error => {
        reject(error);
      });
    });
  }
  public deletePromotion(pid): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('promotions').doc(pid).delete().then((data) => {
        resolve(data);
      }, error => {
        reject(error);
      }).catch(error => {
        reject(error);
      });
    });
  }


  public addNewParcel({ body }) {
    console.log("llego hasta la fn de addnewparcelfront", body);

    return this.http
      .post(`${this.api}/cabify/addNewParcel`, body)
      .pipe(
        map((data: any) => {
          console.log("respuesta de la fn api addnewparcel", data);
          return data;
        })
      );
  }
  // public async proof_of_delivery_configuration({ body }) {

  //     console.log("intentando hacer el post", body)
  //     const cabify_id = body.cabify_id

  //     let proof_configuration = {
  //       "delivered_proofs": {
  //         "ic_proof": {
  //           "active": false
  //         },
  //         "photo_proof": {
  //           "active": true
  //         },
  //       },
  //       "optimize": true
  //     }
  //     console.log("intentando hacer el post")
  //     const headers = {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Accept: "application/json",
  //         Authorization: cabifyAuth,
  //       },
  //     }
  //     this.http.post(`https://delivery.api.cabify.com/v1/parcels/${cabify_id}/proof_configuration`, proof_configuration, headers)
  //     .pipe(
  //       map((data: any) => {
  //         console.log("respuesta de la fn api proof_of_delivery_configuration", data);
  //         return data;
  //       })
  //     )


  // }


  // public getComuna(venue) {

  //   return this.http
  //     .get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${venue.lat},${venue.lng}&key=AIzaSyCffqnZYz97NGi7tlIf-zJMt4dz9wr3G2Y`)
  //     .pipe(
  //       map((data: any) => {
  //         return data;
  //       })
  //     );
  // }

  public cabifyError(param, responseError): Promise<any> {
    let newParam = {
      cabifyError: JSON.stringify(responseError),
      errorDate: new Date
    }
    console.log("lo que llega a cabifyError", param, newParam);
    return new Promise<any>(async (resolve, reject) => {
      this.adb
        .collection("cabifyError")
        .doc()
        .set(newParam)
        .then(async (order: any) => {
          resolve(order);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async programSearchDrivers(orderId) {
    console.log("NUEVO este es el orderId de programSearchDrivers()", orderId.toString());

    fetch('https://us-central1-v-go-app.cloudfunctions.net/programSearchDriver/v1', {
      method: 'POST',
      body: orderId.toString()

    })
  }

  public updateOffer(uid, oid, params: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("users")
        .doc(uid)
        .collection("offers")
        .doc(oid)
        .update(params)
        .then(
          (data) => {
            console.log("actualizado el cupon", oid, params);

            resolve(data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  }

  // sendWhatsapp
  sendWhatsapp(order) {
    console.log("LLEGA A LA API twilio_whats_app", `${this.api}/payments/twilio_whats_app`);
    let body = order

    return this.http
      .post<any>(`${this.api}/payments/twilio_whats_app`, body).subscribe(data => {
        console.log(data);
      })
  }

}
